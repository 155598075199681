<!--
 * @Author: John
 * @Date: 2022-03-08 09:41:30
 * @LastEditTime: 2022-09-23
 * @LastEditors: Please set LastEditors
 * @Description: 收益记录
 * @FilePath: \jjms_ui\src\views\dtsy\list.vue
 * @Copyright John
-->
<template>
    <div>
        <my-breadcrumb :data="breadcrumbData" />
        <div class="content-wrap">
            <div class="toolbar">
                <el-form :inline="true">
                    <jj-select v-model="jjSelectObj" />
                    <el-form-item>
                        <el-date-picker
                            v-model="queryDateRange"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                            </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="query" icon="el-icon-search" style="margin-left: 20px;">查询</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="edit" icon="el-icon-edit-outline">编辑</el-button>
                    </el-form-item>
                    <el-form-item>
                        <span style="color:red; margin-left: 30px;">默认查询近三个月记录（系统最多只展示50条数据）</span>
                    </el-form-item>
                </el-form>
            </div>
            <el-table
                ref="syTable"
                :data="tableData"
                style="width: 100%"
                highlight-current-row
                @current-change="handleCurrentChange"
                >
                <el-table-column
                    width="50"
                    type="index"
                    label="序号"
                 />
                <el-table-column
                    prop="jjCode"
                    label="基金代码"
                    width="100"
                />
                <el-table-column
                    prop="jjName"
                    label="基金名称"
                />
                <el-table-column
                    prop="incomeMoney"
                    label="收益金额"
                    width="100" 
                />
                <el-table-column
                    prop="cashDividend"
                    label="现金分红"
                    width="100" 
                />
                <el-table-column
                    prop="incomeDate"
                    label="收益日期"
                    width="100" 
                />
                <el-table-column
                    prop="createtime"
                    label="更新时间"
                />
            </el-table>
        </div>

        <el-dialog title="编辑收益数据" :visible.sync="dialogFormVisible" width="40%">
            <el-form :model="formData" :rules="formRule" ref="editDtSyForm" label-width="150px" :inline-message="true">
                <el-form-item label="基金名称" prop="jjName">
                    <el-input v-model="formData.jjName" :disabled="true" />
                </el-form-item>
                <el-form-item label="基金代码" prop="jjCode">
                    <el-input v-model="formData.jjCode" :disabled="true" />
                </el-form-item>
                <el-form-item label="收益金额" prop="incomeMoney">
                    <el-input v-model="formData.incomeMoney" />
                </el-form-item>
                <el-form-item label="现金分红" prop="cashDividend">
                    <el-input v-model="formData.cashDividend" />
                </el-form-item>
                <el-form-item label="收益日期" prop="incomeDate">
                    <el-input v-model="formData.incomeDate" :disabled="true" />
                </el-form-item>
                <el-form-item label="最后一次交易日期" prop="transactionDate">
                    <el-input v-model="formData.transactionDate" :disabled="true" />
                </el-form-item>
                <el-form-item label="当日净值" prop="networth">
                    <el-input v-model="formData.networth" :disabled="true" />
                </el-form-item>
                <el-form-item label="前日净值" prop="lastNetworth">
                    <el-input v-model="formData.lastNetworth" :disabled="true" />
                </el-form-item>
                <el-form-item label="当日持仓份额" prop="lastFundShare">
                    <el-input v-model="formData.lastFundShare" :disabled="true" />
                </el-form-item>
                <el-form-item label="前日持仓份额" prop="last2FundShare">
                    <el-input v-model="formData.last2FundShare" :disabled="true" />
                </el-form-item>
                <el-form-item label="收益更新时间" prop="createtime">
                    <el-input v-model="formData.createtime" :disabled="true" />
                </el-form-item>
                <!-- <el-form-item>
                    <div style="color: red;">累计持有市值和累计持有份额会自动计算，一般情况下只有首次定投才需要视情况校准!</div>
                    <div style="color: red;">赎回基金时填写赎回份额， 申购基金时填写实际定投金额!</div>
                </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="updateDtSy">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import myBreadcrumb from '@/components/my-breadcrumb.vue'
import jjSelect from '@/components/jj-select.vue'
import api from '@/api/req_api.js'

export default {
    name: 'dtsy',  // 定投收益
    components: {
        myBreadcrumb,
        jjSelect
    },
    data() {
        return {
            breadcrumbData: [
                {title: '收益管理'},
                {title: '我的收益', url: '/main/dtsy-list'}
            ],
            tableData: null,
            queryDateRange: null,  //查询时间范围,
            currentRow: null,
            dialogFormVisible: false,
            formData: {
                cashDividend: 0,
                incomeMoney: 0
            },
            formRule: {
                incomeMoney: [
                    {required: true, message: '收益金额是必填项', trigger: 'blur'}
                ],
                cashDividend: [
                    {required: true, message: '现金分红是必填项', trigger: 'blur'}
                ]
            },
            jjSelectObj: null
        }
    },
    methods: {
        loadData(startDate, endDate, jjCode) {
            api.queryDtSyList(startDate, endDate, jjCode).then(data => {
                if(data != null) {
                    this.tableData = data;
                } 
            });
        },
        query() {
            let startDate, endDate;
            if(this.queryDateRange) {
                startDate = this.queryDateRange[0];
                endDate = this.queryDateRange[1];
            }
            this.loadData(startDate, endDate, this.jjCode);
        },
        edit() {
            if(!this.currentRow) {
                this.$message.warning('请先选择一条数据!')
                return;
            }
            this.formData = this.currentRow;
            this.dialogFormVisible = true;
        },
        handleCurrentChange(currentRow) {
            this.currentRow = currentRow;
        },
        updateDtSy() {
            this.$refs['editDtSyForm'].validate((valid) => {
                if (valid) {
                    api.updateDtsy(this.formData)
                        .then(data => {
                            if(data != null) {
                                this.$message.success('更新成功！');
                                this.dialogFormVisible = false;
                                this.loadData();
                            }
                        })
                }
            })
        }
    },
    computed: {
        jjCode() {
            if(!this.jjSelectObj) return '';
            return this.jjSelectObj.jjCode;
        }
    },
    mounted() {
        this.loadData();
    }
}
</script>
